import { render, staticRenderFns } from "./InputText.vue?vue&type=template&id=d2bdd390&scoped=true&"
import script from "./InputText.vue?vue&type=script&lang=js&"
export * from "./InputText.vue?vue&type=script&lang=js&"
import style0 from "./InputText.vue?vue&type=style&index=0&id=d2bdd390&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2bdd390",
  null
  
)

export default component.exports